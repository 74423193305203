.nav-list a {
    text-decoration: none;
    color: #555;
}

a {
    text-decoration: none;
}


html,
body {
  padding: 0;
  margin: 0;
}


body, input, button, textarea{
  font: 300 1rem Roboto;
  color: var(--color-text-base);
}
